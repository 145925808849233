/* global $ */
import React, { useState } from "react";
import Loader from "../loader/Loader";
import SuccessModal from "./SuccessModal";

function DeleteModel({
  deleteByKeyId,
  name,
  addCategoryLoader,
  modelRequestData,
  props,
  openSuccessModal,
  setOpenSuccessModal,
}) {
  const [dismissModal, setDismissModal] = useState(null);
  // const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [modelAction, setModelAction] = useState("Delete");
  const recordName = name;

  const handleCloseModal = () => {
    setOpenSuccessModal(false);
  };

  const handleClose = () => {
    $("#" + "deleteRecordModal").modal("hide");
    setOpenSuccessModal(false);
  };
  return (
    <div
      style={{ display: openSuccessModal && "none" }}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade zoomIn"
      id="deleteRecordModal"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header" style={{ paddingBottom: "10px" }}>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mt-2 text-center">
              <lord-icon
                src="https://cdn.lordicon.com/gsqxdxog.json"
                trigger="loop"
                colors="primary:#f7b84b,secondary:#f06548"
                style={{ width: "75px", height: "60px" }}
              ></lord-icon>
              <div class="mt-2 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you sure ?</h4>
                <p class="text-muted mx-4 mb-0">
                  Are you sure you want to delete this record ?
                </p>
              </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button
                type="button"
                class="btn btn-md btn-light cancel-item-btn"
                data-bs-dismiss="modal"
              >
                <span>Cancel</span>
              </button>
              <button
                onClick={() => {
                  deleteByKeyId();
                  // setOpenSuccessModal(true)
                }}
                type="button"
                class="btn btn-md btn-success create-item-btn"
                // data-bs-dismiss="modal"
              >
                {/* {addCategoryLoader && <Loader />} */}
                <span>Yes, Delete It!</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {modelRequestData?.Action === "delete" && (
        <SuccessModal
          handleClose={handleClose}
          setDismissModal={setDismissModal}
          setOpenSuccessModal={setOpenSuccessModal}
          openSuccessModal={openSuccessModal}
          modelAction={modelAction}
          message={recordName}
        />
      )}
    </div>
  );
}

export default DeleteModel;
