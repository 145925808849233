import React from "react";

const BackButtonSvg = ({ onClick }) => {
  return (
    <>
      <span className="backButtonCss" onClick={onClick}>
        <svg
          className="mt-2"
          stroke="#00afef"
          style={{ cursor: "pointer" }}
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="30"
          fill="currentColor"
          class="bi bi-arrow-left-short back-button"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
          />
        </svg>
      </span>
    </>
  );
};

export default BackButtonSvg;
