const BusinessSoleTraderVariables = [
  "$Accountant.SoleTrader.FirstName$",
  "$Accountant.SoleTrader.Email$",
  "$Accountant.SoleTrader.LastName$",
  "$Accountant.SoleTrader.Phone$",
  "$Accountant.TradingName$",
  "$Accountant.TradingAddress$",
  "$Accountant.TradingAddressWithLineBreak$",
  "$Accountant.SoleTrader.Address$",
  "$Accountant.SoleTrader.AddressWithLineBreak$",
  "$Accountant.AffiliatedAccountingBodyName$",
  // "$Accountant.CountryIncorporatedIn$",
  "$CurrentDate$",
  "$DocumentId$",
  "$DocumentCreationDate$",
  "$DocumentSentDate$",
  "$Accountant.SignatoryName$",
  "$Accountant.SignatureImageUrl$",
  "$Accountant.BusinessTagline$",
  // "$Affiliated.Accounting.Body.Name$",
  "$Accountant.WebsiteOfAffiliatedAccountingBodyName$",
  "$Accountant.LogoUrl$"

];

const TncCustomBusinessSoleTraderVariables = [
  "$Accountant.SoleTrader.FirstName$",
  "$Accountant.SoleTrader.Email$",
  "$Accountant.SoleTrader.LastName$",
  "$Accountant.SoleTrader.Phone$",
  "$Accountant.TradingName$",
  "$Accountant.TradingAddress$",
  "$Accountant.TradingAddressWithLineBreak$",
  "$Accountant.SoleTrader.Address$",
  "$Accountant.SoleTrader.AddressWithLineBreak$",
  "$Accountant.AffiliatedAccountingBodyName$",
  "$Accountant.CountryIncorporatedIn$",
  "$CurrentDate$",
];

const BusinessPartnerShipVariables = [
  "$Accountant.Partner.FirstName$",
  "$Accountant.Partner.LastName$",
  "$Accountant.Partner.Email$",
  "$Accountant.Partner.Phone$",
  "$Accountant.TradingName$",
  "$Accountant.TradingAddress$",
  "$Accountant.TradingAddressWithLineBreak$",
  "$Accountant.Partner.Address$",
  "$Accountant.Partner.AddressWithLineBreak$",
  "$Accountant.AffiliatedAccountingBodyName$",
  "$Accountant.CountryIncorporatedIn$",
  "$CurrentDate$",
  "$DocumentId$",
  "$DocumentCreationDate$",
  "$DocumentSentDate$",
  "$Accountant.SignatoryName$",
  "$Accountant.SignatureImageUrl$",
  "$Accountant.BusinessTagline$",
  // "$Affiliated.Accounting.Body.Name$",
  "$Accountant.WebsiteOfAffiliatedAccountingBodyName$",
  "$Accountant.LogoUrl$"
];
const TncCustomBusinessPartnerShipVariables = [
  "$Accountant.Partner.FirstName$",
  "$Accountant.Partner.LastName$",
  "$Accountant.Partner.Email$",
  "$Accountant.Partner.Phone$",
  "$Accountant.TradingName$",
  "$Accountant.TradingAddress$",
  "$Accountant.TradingAddressWithLineBreak$",
  "$Accountant.Partner.Address$",
  "$Accountant.Partner.AddressWithLineBreak$",
  "$Accountant.AffiliatedAccountingBodyName$",
  "$Accountant.CountryIncorporatedIn$",
  "$CurrentDate$",
];

const BusinessLLpVariables = [
  "$Accountant.Officer.FirstName$",
  "$Accountant.Officer.LastName$",
  "$Accountant.Officer.Email$",
  "$Accountant.Officer.Phone$",
  "$Accountant.TradingName$",
  "$Accountant.TradingAddress$",
  "$Accountant.TradingAddressWithLineBreak$",
  "$Accountant.Company.Name$",
  "$Accountant.Company.Number$",
  "$Accountant.Company.RegisteredAddress$",
  "$Accountant.Company.RegisteredAddressWithLineBreak$",
  "$Accountant.AffiliatedAccountingBodyName$",
  "$Accountant.CountryIncorporatedIn$",
  "$CurrentDate$",
  "$DocumentId$",
  "$DocumentCreationDate$",
  "$DocumentSentDate$",
  "$Accountant.SignatoryName$",
  "$Accountant.SignatureImageUrl$",
  "$Accountant.BusinessTagline$",
  // "$Affiliated.Accounting.Body.Name$",
  "$Accountant.WebsiteOfAffiliatedAccountingBodyName$",
  "$Accountant.LogoUrl$"
];
const TncCustomBusinessLLpVariables = [
  "$Accountant.Officer.FirstName$",
  "$Accountant.Officer.LastName$",
  "$Accountant.Officer.Email$",
  "$Accountant.Officer.Phone$",
  "$Accountant.TradingName$",
  "$Accountant.TradingAddress$",
  "$Accountant.TradingAddressWithLineBreak$",
  "$Accountant.Company.Name$",
  "$Accountant.Company.Number$",
  "$Accountant.Company.RegisteredAddress$",
  "$Accountant.Company.RegisteredAddressWithLineBreak$",
  "$Accountant.AffiliatedAccountingBodyName$",
  "$Accountant.CountryIncorporatedIn$",
  "$CurrentDate$",
];

const BusinessCompanyVariables = [
  "$Accountant.Officer.FirstName$",
  "$Accountant.Officer.LastName$",
  "$Accountant.Officer.Email$",
  "$Accountant.Officer.Phone$",
  "$Accountant.TradingName$",
  "$Accountant.TradingAddress$",
  "$Accountant.TradingAddressWithLineBreak$",
  "$Accountant.Company.Name$",
  "$Accountant.Company.Number$",
  "$Accountant.Company.RegisteredAddress$",
  "$Accountant.Company.RegisteredAddressWithLineBreak$",
  "$Accountant.AffiliatedAccountingBodyName$",
  "$Accountant.CountryIncorporatedIn$",
  "$CurrentDate$",
  "$DocumentId$",
  "$DocumentCreationDate$",
  "$DocumentSentDate$",
  "$Accountant.SignatoryName$",
  "$Accountant.SignatureImageUrl$",
  "$Accountant.BusinessTagline$",
  // "$Affiliated.Accounting.Body.Name$",
  "$Accountant.WebsiteOfAffiliatedAccountingBodyName$",
  "$Accountant.LogoUrl$"
];
const TncCustomBusinessCompanyVariables = [
  "$Accountant.Officer.FirstName$",
  "$Accountant.Officer.LastName$",
  "$Accountant.Officer.Email$",
  "$Accountant.Officer.Phone$",
  "$Accountant.TradingName$",
  "$Accountant.TradingAddress$",
  "$Accountant.TradingAddressWithLineBreak$",
  "$Accountant.Company.Name$",
  "$Accountant.Company.Number$",
  "$Accountant.Company.RegisteredAddress$",
  "$Accountant.Company.RegisteredAddressWithLineBreak$",
  "$Accountant.AffiliatedAccountingBodyName$",
  "$Accountant.CountryIncorporatedIn$",
  "$CurrentDate$",
];
export default {
  BusinessSoleTraderVariables,
  BusinessPartnerShipVariables,
  BusinessLLpVariables,
  BusinessCompanyVariables,
  TncCustomBusinessSoleTraderVariables,
  TncCustomBusinessPartnerShipVariables,
  TncCustomBusinessCompanyVariables,
  TncCustomBusinessLLpVariables,
};
