const Reminderjson = {
  
    statuses: [
      {
        "id": 0,
        "name": "All"
      },
      {
        "id": 1,
        "name": "Proposal Sent"
      },
      {
        "id": 2,
        "name": "Proposal Accepted"
      },
      {
        "id": 3,
        "name": "Proposal Declined"
      },
      {
        "id": 4,
        "name": "Proposal Viewed"
      },
      {
        "id": 5,
        "name": "Engagement Letter Sent"
      },
      {
        "id": 6,
        "name": "Engagement Letter Viewed"
      },
      {
        "id": 7,
        "name": "Engagement Letter Signed"
      },
      {
        "id": 8,
        "name": "Engagement Letter Declined"
      }
    ],
  
  

  emailTemplates: [
      { id: 1, name: "Template 1" },
      { id: 2, name: "Template 2" },
      { id: 3, name: "Template 3" }
  ],

  emailAddresses: [
      { id: 1, name: "Send Email to Prospects" },
      { id: 2, name: "Send Email To Accountants"},
      { id: 3, name: "Send Email To Both"},


  ],

  
  periods: [
    { id: 1, name: "After" }
    
  ],

  triggerPoints: [
      { id: 1, name: "Proposal Sent Date" },
      { id: 2, name: "Proposal Accepted Date" },
      { id: 3, name: "Proposal Declined Date" },
      { id: 4, name: "Proposal Viewed Date" },

      { id: 5, name: "Engagement Letter Sent Date" },
      { id: 6, name: "Engagement Letter Viewed Date" },
      { id: 7, name: "Engagement Letter Signed Date" },
      { id: 8, name: "Engagement Letter Declined Date" }
    
    ],

  repeats: [
      { id: 1, name: "Yes" },
      { id: 2, name: "No" }
  ],

  frequencies: [
      { id: 1, name: " Daily" },
      { id: 2, name: "Once every two days" },
      { id: 3, name: "Once every three days" },
      { id: 4, name: "Once every five days" },
      { id: 5, name: "Weekly" },
      { id: 6, name: "Every 10 days" },
      { id: 7, name: "Fortnightly" },
      { id: 8, name: "Fortnightly" },
      { id: 9, name: "Monthly" },
      { id: 10, name: "Quarterly" },


  ],
 
};

export default Reminderjson;



