/* global $ */
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthContext from "./AuthContext/AuthContext.jsx";
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";
import { store } from "./redux/store/store";
import { Provider } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./config/microsoftConfig.js";
import { MsalProvider } from "@azure/msal-react";
import { Navigate } from "react-router-dom";
import Login from "./Auth/login/Login.jsx";

// m sal should be instantiated outside of the component tree to prevent it from being re-instantiated on
// re-renders

const msalInstance = new PublicClientApplication(msalConfig);

// default to using the first account of no account is active on page load

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // account selection logic is add dependent
  // msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// listen for sign-in event and set active account

// const common = store.getState().Storage; // Adjust this to match your actual store structure
// let isReloadInProgress = false;
// const handleStorageChange = (event) => {
//   // if (event.key === 'your_storage_key' && event.newValue === null) {
//   //   // Dispatch the logout action
//   //   store.dispatch(logoutUser());
//   // }
//   if (isReloadInProgress) {
//     return;
//   }
//   alert(common.token === "")
//   if (common.token === "") {
//     const currentUrl = window.location.href;
//     //alert('Current URL:' + currentUrl);
//     if (!currentUrl.includes("login") &&
//       !currentUrl.includes("accept-invite") &&
//       !currentUrl.includes("forget-password") &&
//       !currentUrl.includes("reset-password") &&
//       !currentUrl.includes("registration") &&
//       !currentUrl.includes("activate")
//     ) {
//       // Set the flag to indicate a reload is in progress
//       isReloadInProgress = true;
//       // Reload the page
//       window.location.reload();
//     }
//   }
// };
// window.addEventListener('storage', handleStorageChange);
// Listen for changes in Tab 2
// window.addEventListener('storage', (event) => {
//   if (event.key === 'StayUserLogin') {
//     const newValue = JSON.parse(event.newValue);
//     // Handle the updated state
//     alert('New Value in Tab 2:' + newValue);
//   }
// });
msalInstance.addEventCallback((event) => {
  if (event.EventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const AppWrapper = () => {
  return (
    // <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistores}>
          <AuthContext>
            <App />
            <ToastContainer />
          </AuthContext>
        </PersistGate>
      </Provider>
    </MsalProvider>
    // </React.StrictMode>
  );
};

const root = createRoot(document.getElementById("root"));

let persistores = persistStore(store);

root.render(<AppWrapper />);

reportWebVitals();
