/* global $ */
import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColorContext } from "./ColorContext";
import { resetState, updateState } from "../redux/Persist";
import { CalenderFilterEnum } from "../Middleware/enums";
import moment from "moment/moment";
import { GetSaveImage } from "../redux/Services/SaveImage/SaveImageApi";


const initialState = {
  loading: false,
};

export const AuthContextProvider = createContext(initialState);

const AuthContext = ({ children }) => {
  let getUserPersonalizeSettingApiCallCount = 0;
  /* -------------------------------------------------------------------------- */
  /*                                State Declare                               */
  /* -------------------------------------------------------------------------- */
  const dispatch = useDispatch();
  const [activeOrganization, setActiveOrganization] = useState([]);
  const [
    activeOrganizationSubscriptionPlan,
    setActiveOrganizationSubscriptionPlan,
  ] = useState(JSON.parse(localStorage.getItem("subscriptionPlan")) || null);
  const [userPermissions, setUserPermissions] = useState(null);
  const [maxCountToRecallApi, setmaxCountToRecallApi] = useState(5);
  const [topbar, setTopbar] = useState("block");
  const [currencySymbols, setCurrencySymbols] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loginLoader, setLoginLoader] = useState(false);
  const [professionTypeListData, setProfessionTypeList] = useState([]);
  const [prospectName, setProspectName] = useState("");
  const [updatedProspectName, setUpdatedProspectName] = useState("");
  const [proposalName, setProposalName] = useState("");
  const [updatedProposalName, setUpdatedProposalName] = useState("");
  const [EngagementName, setEngagementName] = useState("");
  const [updatedEngagementName, setUpdatedEngagementName] = useState("");
  const [DefaultVariables, setDefaultVariables] = useState(false);
  const [isAddUpdatePurchaseDone, setIsAddUpdatePurchaseDone] = useState(false);
  const [logoutTimeUpModal, setLogoutTimeUpModal] = useState({
    isPopupOpen: false,
    popupOpenTime: null,
    popupCloseTime: null,
  });

  const problematicInputRef = useRef(null);
  const [RequireErrorMessage, setRequireErrorMessage] = useState(false);
  const [DefaultProspect, setDefaultProspect] = useState(false);
  const [DefaultProposal, setDefaultProposal] = useState(false);
  const [listCount, setListCount] = useState(0);
  const [deviceHeight, setDeviceHeight] = useState(window.innerHeight);
  const [isMobileRecords, setIsMobileRecords] = useState(0);
  const [desktopRecords, setDesktopRecords] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [orgLoaderList, setOrgLoaderList] = useState(false);
  const [DashboardCountListLoader, setDashboardCountListLoader] =
    useState(false);
  const [DashboardActivityLogLoader, setDashboardActivityLogLoader] =
    useState(false);

  // access permission count
  const [accessCount, SetAccessCount] = useState(-1);
  const totalPage = isMobile
    ? Math.ceil(listCount / isMobileRecords)
    : Math.ceil(listCount / desktopRecords);
  const [DefaultEngagementLatter, setDefaultEngagementLatter] = useState(false);
  let engagementSetting;
  let proposalSetting;
  let prospectSetting;

  const common = useSelector((state) => state.Storage);
  // User Access Permission
  const [userAccessData, setUserAccessData] = useState({
    Dashboard_CanView: true,

    // Prospect:
    Admin_Prospect_CanAdd: false,
    Admin_Prospect_CanEdit: false,
    Admin_Prospect_CanDelete: false,
    Admin_Prospect_CanView: false,

    //Proposal :
    Admin_Proposal_CanAdd: false,
    Admin_Proposal_CanEdit: false,
    Admin_Proposal_CanDelete: false,
    Admin_Proposal_CanView: false,

    //Engagement Latter :
    Admin_Engagement_Latter_CanAdd: false,
    Admin_Engagement_Latter_CanEdit: false,
    Admin_Engagement_Latter_CanDelete: false,
    Admin_Engagement_Latter_CanView: false,

    //Organisation:
    Organisation_CanAdd: false,
    Organisation_CanEdit: false,
    Organisation_CanDelete: false,
    Organisation_CanView: false,

    //SuperAdmin User:
    User_CanAdd: false,
    User_CanEdit: false,
    User_CanDelete: false,
    User_CanView: false,

    // Subscription:
    Subscription_CanAdd: false,
    Subscription_CanEdit: false,
    Subscription_CanDelete: false,
    Subscription_CanView: false,

    //Config Admin:

    Admin_Config_CanView: false,
    Admin_Setting_CanView: false,

    //Config Admin Service Cat:
    Admin_Config_ServiceCat_CanAdd: false,
    Admin_Config_ServiceCat_CanEdit: false,
    Admin_Config_ServiceCat_CanDelete: false,
    Admin_Config_ServiceCat_CanView: false,

    //Config Admin Service:
    Admin_Config_Service_CanAdd: false,
    Admin_Config_Service_CanEdit: false,
    Admin_Config_Service_CanDelete: false,
    Admin_Config_Service_CanView: false,

    //Config Admin Package:
    Admin_Config_ServicePackage_CanAdd: false,
    Admin_Config_ServicePackage_CanEdit: false,
    Admin_Config_ServicePackage_CanDelete: false,
    Admin_Config_ServicePackage_CanView: false,

    //Config Admin Global constant:
    Admin_Config_Global_Constant_CanAdd: false,
    Admin_Config_Global_Constant_CanEdit: false,
    Admin_Config_Global_Constant_CanDelete: false,
    Admin_Config_Global_Constant_CanView: false,

    //Config Admin GlobalDriver:
    Admin_Config_Global_Driver_CanAdd: false,
    Admin_Config_Global_Driver_CanEdit: false,
    Admin_Config_Global_Driver_CanDelete: false,
    Admin_Config_Global_Driver_CanView: false,

    //Config Admin EL/PL Template:
    Admin_Config_Template_CanAdd: false,
    Admin_Config_Template_CanEdit: false,
    Admin_Config_Template_CanDelete: false,
    Admin_Config_Template_CanView: false,

    Admin_Setting_CanView: false,

    //Config Admin Term and Condition:
    Admin_Config_TnC_CanAdd: false,
    Admin_Config_TnC_CanEdit: false,
    Admin_Config_TnC_CanDelete: false,
    Admin_Config_TnC_CanView: false,

    //Config Admin Setting User:
    Admin_Setting_user_CanAdd: false,
    Admin_Setting_user_CanEdit: false,
    Admin_Setting_user_CanDelete: false,
    Admin_Setting_user_CanView: false,

    //Config Admin Setting User:
    Admin_Setting_AccessKeyCanAdd: false,
    Admin_Setting_AccessKeyCanEdit: false,
    Admin_Setting_AccessKeyCanDelete: false,
    Admin_Setting_AccessKeyCanView: false,
    //Config Admin Setting User:

    Admin_Setting_Practice_Config_CanAdd: false,
    Admin_Setting_Practice_Config_CanEdit: false,
    Admin_Setting_Practice_Config_CanDelete: false,
    Admin_Setting_Practice_Config_CanView: false,

    //Config Admin Setting User:
    Admin_Activity_Log_CanAdd: false,
    Admin_Activity_Log_CanEdit: false,
    Admin_Activity_Log_CanDelete: false,
    Admin_Activity_Log_CanView: false,

    //Config Admin Setting User:
    Admin_Personalize_SettingCanAdd: false,
    Admin_Personalize_SettingCanEdit: false,
    Admin_Personalize_SettingCanDelete: false,
    Admin_Personalize_SettingCanView: false,

    //Config Admin Email Template:
    Admin_Config_Email_Template_CanAdd: false,
    Admin_Config_Email_Template_CanEdit: false,
    Admin_Config_Email_Template_CanDelete: false,
    Admin_Config_Email_Template_CanView: false,

    SuperAdmin_Setting_CanView: true,
    //Config SuperAdmin:

    SuperAdmin_Config_CanView: false,

    //Config SuperAdmin Service Cat:
    SuperAdmin_Config_ServiceCat_CanAdd: false,
    SuperAdmin_Config_ServiceCat_CanEdit: false,
    SuperAdmin_Config_ServiceCat_CanDelete: false,
    SuperAdmin_Config_ServiceCat_CanView: false,

    //Config SuperAdmin Service:
    SuperAdmin_Config_Service_CanAdd: false,
    SuperAdmin_Config_Service_CanEdit: false,
    SuperAdmin_Config_Service_CanDelete: false,
    SuperAdmin_Config_Service_CanView: false,

    //Config SuperAdmin Package:
    SuperAdmin_Config_ServicePackage_CanAdd: false,
    SuperAdmin_Config_ServicePackage_CanEdit: false,
    SuperAdmin_Config_ServicePackage_CanDelete: false,
    SuperAdmin_Config_ServicePackage_CanView: false,

    //Config SuperAdmin Global constant:
    SuperAdmin_Config_Global_Constant_CanAdd: false,
    SuperAdmin_Config_Global_Constant_CanEdit: false,
    SuperAdmin_Config_Global_Constant_CanDelete: false,
    SuperAdmin_Config_Global_Constant_CanView: false,

    //Config SuperAdmin GlobalDriver:
    SuperAdmin_Config_Global_Driver_CanAdd: false,
    SuperAdmin_Config_Global_Driver_CanEdit: false,
    SuperAdmin_Config_Global_Driver_CanDelete: false,
    SuperAdmin_Config_Global_Driver_CanView: false,

    //Config SuperAdmin Subscription0-Package:
    SuperAdmin_Config_Subscription_Package_CanAdd: false,
    SuperAdmin_Config_Subscription_Package_CanEdit: false,
    SuperAdmin_Config_Subscription_Package_CanDelete: false,
    SuperAdmin_Config_Subscription_Package_CanView: false,

    //Config SuperAdmin Subscription0-User:
    SuperAdmin_Config_Subscription_User_CanAdd: false,
    SuperAdmin_Config_Subscription_User_CanEdit: false,
    SuperAdmin_Config_Subscription_User_CanDelete: false,
    SuperAdmin_Config_Subscription_User_CanView: false,

    //Config SuperAdmin Subscription0-Invoices:
    SuperAdmin_Config_Subscription_Invoices_CanAdd: false,
    SuperAdmin_Config_Subscription_Invoices_CanEdit: false,
    SuperAdmin_Config_Subscription_Invoices_CanDelete: false,
    SuperAdmin_Config_Subscription_Invoices_CanView: false,

    //Config SuperAdmin EL/PL Template:
    SuperAdmin_Config_Template_CanAdd: false,
    SuperAdmin_Config_Template_CanEdit: false,
    SuperAdmin_Config_Template_CanDelete: false,
    SuperAdmin_Config_Template_CanView: false,

    //Config SuperAdmin Term and Condition:
    SuperAdmin_Config_TnC_CanAdd: false,
    SuperAdmin_Config_TnC_CanEdit: false,
    SuperAdmin_Config_TnC_CanDelete: false,
    SuperAdmin_Config_TnC_CanView: false,

    //Config SuperAdmin Email Template:
    SuperAdmin_Config_Email_Template_CanAdd: false,
    SuperAdmin_Config_Email_Template_CanEdit: false,
    SuperAdmin_Config_Email_Template_CanDelete: false,
    SuperAdmin_Config_Email_Template_CanView: false,

    SuperAdmin_Setting_Email_Template_CanAdd: false,
    SuperAdmin_Setting_Email_Template_CanEdit: false,
    SuperAdmin_Setting_Email_Template_CanDelete: false,
    SuperAdmin_Setting_Email_Template_CanView: false,

    SuperAdmin_Setting_User_Role_CanAdd: false,
    SuperAdmin_Setting_User_Role_CanEdit: false,
    SuperAdmin_Setting_User_Role_CanDelete: false,
    SuperAdmin_Setting_User_Role_CanView: false,

    SuperAdmin_Personalize_SettingCanAdd: false,
    SuperAdmin_Personalize_SettingCanEdit: false,
    SuperAdmin_Personalize_SettingCanDelete: false,
    SuperAdmin_Personalize_SettingCanView: false,
  });

  useEffect(() => {
    if (
      orgLoaderList &&
      DashboardActivityLogLoader &&
      DashboardCountListLoader
    ) {
      setLoader(false);
    }
  }, [orgLoaderList, DashboardActivityLogLoader, DashboardCountListLoader]);

  useEffect(() => {
    let timeoutId;
    if (logoutTimeUpModal.isPopupOpen) {
      timeoutId = setTimeout(() => {
        CheckUsersIdleStateAfterSessionTimeoutPopUpOpen()
      }, 10000);
    }
    // Cleanup the timeout if isPopupOpen becomes false or on component unmount
    if (!logoutTimeUpModal.isPopupOpen && timeoutId) {
      clearTimeout(timeoutId);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };

  }, [logoutTimeUpModal])


  // const escapeRegExp = (string) => {
  //   return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  // };

  const updateImageUrlsInHtml = async (htmlContent) => {
    // Regular expression to match base64 images
    const base64ImageRegex = /<img[^>]+src="data:image\/(png|jpeg|jpg);base64,([^"]*)"/g;
    const matches = [...htmlContent.matchAll(base64ImageRegex)];
    setLoader(true)

    const urlMap = new Map();

    for (const [index, match] of matches.entries()) {
      const base64Data = match[2];
      const contentType = `image/${match[1]}`;
      const filename = `image-${index}.${match[1]}`;


      const userKeyID = common.userKeyID; // Ensure this is defined or passed in
      const ApiObject_param = { base64Data, contentType, filename, userKeyID };

      try {
        const response = await GetSaveImage(ApiObject_param);
        if (response.data.statusCode === 200) {
          const imgUrl = response.data.imageUrl;

          urlMap.set(base64Data, imgUrl);
        } else {
          console.error('Server response not successful:', response.data);
        }
      } catch (error) {
        console.error('Error saving image:', error);
      }
    }

    let updatedHtml = htmlContent;

    // Iterate over URL map and replace base64 data with URLs
    urlMap.forEach((newUrl, base64Data) => {
      try {
        // Use a more generic approach to split and replace
        updatedHtml = updatedHtml.split(`data:image/png;base64,${base64Data}`).join(newUrl);
        updatedHtml = updatedHtml.split(`data:image/jpeg;base64,${base64Data}`).join(newUrl);
        updatedHtml = updatedHtml.split(`data:image/jpg;base64,${base64Data}`).join(newUrl);
      } catch (error) {
        console.error('Error replacing base64 data:', error);
      }
    });

    setLoader(false)
    return updatedHtml;
  };

  const updateTemplateList = async (ListArray, ModuleName) => {
    if (ModuleName === "Email_Template" || ModuleName === "Super_Admin_Email_Template") {
      try {
        const updatedTemplate = await Promise.all(ListArray.map(async (item) => {
          return {
            ...item,
            htmlContent: await updateImageUrlsInHtml(item.htmlContent)
          };
        }));
        return updatedTemplate;
      } catch (error) {
        console.error('Error updating template list:', error);
      }
    }

  };

  const CheckUsersIdleStateAfterSessionTimeoutPopUpOpen = () => {
    if (logoutTimeUpModal.isPopupOpen) {
      Logout()
    }
  }

  const Logout = () => {
    localStorage.clear();
    dispatch(resetState());
    handleReloadClick()
    window.location.reload(true);
    // navigate("/login");
  }
  const handleReloadClick = () => {
    const broadcastChannel = new BroadcastChannel("reloadChannel");
    broadcastChannel.postMessage("reload");
  };
  // Check here screen height and width of all device (mobile,tablet,desktop )
  // useEffect(() => {
  //   const handleResize = () => {
  //     const width = window.innerWidth;
  //     const height = window.innerHeight;
  //     if (width <= 768) {
  //       setIsMobile(true);
  //     } else if (width > 768) {
  //       setIsMobile(false);
  //     }
  //     // Set isMobile based on width
  //     setDeviceHeight(height); // Set device height
  //     if (height <= 375) {
  //       setDesktopRecords(6);
  //       setIsMobileRecords(6);
  //     } else if (height >= 376 && height <= 667) {
  //       setDesktopRecords(6);
  //       setIsMobileRecords(6);
  //     } else if (height >= 668 && height <= 740) {
  //       setDesktopRecords(7);
  //       setIsMobileRecords(7);
  //     } else if (height >= 741 && height <= 841) {
  //       // Adjusted this condition to properly capture heights >= 841
  //       setDesktopRecords(7);
  //       setIsMobileRecords(10);
  //     } else if (height >= 841 && height <= 1480) {
  //       setDesktopRecords(14);
  //       setIsMobileRecords(10);
  //     } else if (height >= 1481) {
  //       setDesktopRecords(15);
  //       setIsMobileRecords(10);
  //     } else {
  //       setDesktopRecords(6);
  //       setIsMobileRecords(10);
  //     }
  //   };
  //   handleResize(); // Set initial viewport size
  //   window.addEventListener("resize", handleResize); // Listen for viewport changes
  //   return () => window.removeEventListener("resize", handleResize); // Clean up on unmount
  // }, []);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width <= 768) {
        setIsMobile(true);
      } else if (width > 768) {
        setIsMobile(false);
      }
      // Set isMobile based on width
      setDeviceHeight(height); // Set device height
      if (height <= 360) {
        setDesktopRecords(1);
        setIsMobileRecords(1);
      } else if (height >= 361 && height <= 400) {
        setDesktopRecords(2);
        setIsMobileRecords(2);
      } else if (height >= 401 && height <= 440) {
        setDesktopRecords(3);
        setIsMobileRecords(3);
      } else if (height >= 440 && height <= 520) {
        // Adjusted this condition to properly capture heights >= 841
        setDesktopRecords(4);
        setIsMobileRecords(4);
      } else if (height >= 521 && height <= 600) {
        setDesktopRecords(5);
        setIsMobileRecords(5);
      } else if (height >= 601 && height <= 660) {
        setDesktopRecords(6);
        setIsMobileRecords(6);
      } else if (height >= 661 && height <= 700) {
        setDesktopRecords(7);
        setIsMobileRecords(7);
      } else if (height >= 701 && height <= 740) {
        setDesktopRecords(8);
        setIsMobileRecords(8);
      } else if (height >= 741 && height <= 780) {
        setDesktopRecords(9);
        setIsMobileRecords(9);
      } else if (height >= 781 && height <= 820) {
        setDesktopRecords(9);
        setIsMobileRecords(9);
      } else if (height >= 801 && height <= 840) {
        setDesktopRecords(10);
        setIsMobileRecords(10);
      } else if (height >= 840) {
        setDesktopRecords(11);
        setIsMobileRecords(11);
      } else {
        setDesktopRecords(6);
        setIsMobileRecords(10);
      }
    };
    handleResize(); // Set initial viewport size
    window.addEventListener("resize", handleResize); // Listen for viewport changes
    return () => window.removeEventListener("resize", handleResize); // Clean up on unmount
  }, []);
  useEffect(() => {
    localStorage.setItem("accessCount", accessCount);
  }, [accessCount]);

  useEffect(() => {
    SetAccessData();
  }, [activeOrganization]);

  useEffect(() => {
    if (common.token) {
      dispatch(
        updateState({
          isUpdateRole: false,
        })
      );
    }
  }, [common.isUpdateRole, common.userKeyID]);

  useEffect(() => {
    if (common.token) {
      let userThemeSettingLocalStorage = localStorage.getItem(
        "userThemeSettingLocalStorage"
      );
      if (
        userThemeSettingLocalStorage === undefined ||
        userThemeSettingLocalStorage === null
      ) {
        GetUserPersonalizeSettingData(common.userKeyID);
      } else {
        GetUserPersonalizeSettingDataFromLocalStorage();
      }
    }
  }, [common.userKeyID, common.token]);

  // const scrollUptoCurrentPosition = (e, scrollbarContainerDivRef) => {
  //   // Check if the currently focused element is an input field
  //   const activeElement = document.activeElement;
  //   if (activeElement && activeElement.tagName === "INPUT") {
  //     if (scrollbarContainerDivRef.current) {
  //       // Calculate the position to scroll based on the click event
  //       const clickedPosition =
  //         e.clientY -
  //         scrollbarContainerDivRef.current.getBoundingClientRect().top;

  //       // Calculate the remaining scroll distance from the top
  //       const remainingScroll =
  //         scrollbarContainerDivRef.current.scrollTop + clickedPosition - 70;
  //       scrollbarContainerDivRef.current.scrollTop = remainingScroll;
  //     }
  //   }
  // };
  // const scrollUptoCurrentPosition = (e, scrollbarContainerDivRef) => {
  //   // Check if the currently focused element is part of a React Select component
  //   const activeElement = document.activeElement;
  //   const isReactSelectInput = activeElement && activeElement.id.startsWith('react-select-') && activeElement.tagName === 'INPUT';

  //   if (isReactSelectInput) {
  //     if (scrollbarContainerDivRef.current) {
  //       // Calculate the position to scroll based on the click event
  //       const clickedPosition =
  //         e.clientY -
  //         scrollbarContainerDivRef.current.getBoundingClientRect().top;

  //       // Calculate the remaining scroll distance from the top
  //       const targetScroll =
  //         scrollbarContainerDivRef.current.scrollTop + clickedPosition - 130;

  //       // Smooth scroll function
  //       const smoothScroll = (start, end, duration) => {
  //         const startTime = performance.now();

  //         const animateScroll = (currentTime) => {
  //           const elapsedTime = currentTime - startTime;
  //           const progress = Math.min(elapsedTime / duration, 1);
  //           scrollbarContainerDivRef.current.scrollTop = start + (end - start) * progress;

  //           if (progress < 1) {
  //             requestAnimationFrame(animateScroll);
  //           }
  //         };

  //         requestAnimationFrame(animateScroll);
  //       };

  //       const startScroll = scrollbarContainerDivRef.current.scrollTop;
  //       const duration = 500; // Duration in milliseconds
  //       smoothScroll(startScroll, targetScroll, duration);
  //     }
  //   }
  // };

  const scrollUptoCurrentPosition = (e, scrollbarContainerDivRef) => {
    const activeElement = document.activeElement;
    const isReactSelectInput = activeElement && activeElement.id.startsWith('react-select-') && activeElement.tagName === 'INPUT';

    if (isReactSelectInput && scrollbarContainerDivRef.current) {
      const containerRect = scrollbarContainerDivRef.current.getBoundingClientRect();
      const clickedPosition = e.clientY - containerRect.top;
      const containerHeight = containerRect.height;

      // Calculate the target scroll position
      let targetScroll = scrollbarContainerDivRef.current.scrollTop + clickedPosition - containerHeight / 2;

      // Ensure we don't scroll past the bottom
      const maxScroll = scrollbarContainerDivRef.current.scrollHeight - containerHeight;
      targetScroll = Math.min(targetScroll, maxScroll);

      // Ensure we don't scroll above the top
      targetScroll = Math.max(targetScroll, 0);

      const smoothScroll = (start, end, duration) => {
        const startTime = performance.now();

        const animateScroll = (currentTime) => {
          const elapsedTime = currentTime - startTime;
          const progress = Math.min(elapsedTime / duration, 1);
          scrollbarContainerDivRef.current.scrollTop = start + (end - start) * progress;

          if (progress < 1) {
            requestAnimationFrame(animateScroll);
          }
        };

        requestAnimationFrame(animateScroll);
      };

      const startScroll = scrollbarContainerDivRef.current.scrollTop;
      const duration = 500; // Duration in milliseconds
      smoothScroll(startScroll, targetScroll, duration);
    }
  };
  // Example usage: add an event listener to call the function on a specific event
  // Assume `scrollbarContainerDivRef` is a React ref attached to the container div
  document.addEventListener("click", (e) => {
    const scrollbarContainerDivRef = {
      current: document.getElementById("scrollbarContainer"),
    };
    scrollUptoCurrentPosition(e, scrollbarContainerDivRef);
  });

  const handleErrorMessage = (errorMessage) => {
    const modifiedErrorMessage = errorMessage?.toLowerCase();

    if (modifiedErrorMessage?.includes("please inactive this record first")) {
      return "Please change the status to InActive first.";
    }

    // If the error message does not match the condition, return the original error message
    return errorMessage;
  };

  const scrollUpDownByElementID = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const GetUserPersonalizeSettingDataFromLocalStorage = () => {
    // Get the JSON-formatted string from localStorage
    let userThemeSettingLocalStorage = localStorage.getItem(
      "userThemeSettingLocalStorage"
    );

    if (userThemeSettingLocalStorage) {
      // Parse the JSON string to a JavaScript object
      let userThemeSettings = JSON.parse(userThemeSettingLocalStorage);

      // Check if userThemeSettings is not null or undefined
      if (userThemeSettings) {
        // Now, userThemeSettings is a JavaScript object containing the parsed JSON data
        //alert(JSON.stringify(userThemeSettings));

        engagementSetting = userThemeSettings.find(
          (item) => item.settingName === "VariableEngagementName"
        );
        proposalSetting = userThemeSettings.find(
          (item) => item.settingName === "VariableProposalName"
        );
        prospectSetting = userThemeSettings.find(
          (item) => item.settingName === "VariableProspectName"
        );
      }
    }
  };

  // Global Date Filter

  const GetCustomDate = (dateFormat, dateType) => {
    const today = moment(); // Current date
    let _fromDate = null;
    let _toDate = null;

    if (dateType === CalenderFilterEnum.All) {
      _fromDate = null;
      _toDate = null;
    } else if (dateType === CalenderFilterEnum.This_Week) {
      _fromDate = today.clone().startOf("week");
      _toDate = today.clone().endOf("week");
    } else if (dateType === CalenderFilterEnum.Last_Week) {
      _fromDate = today.clone().subtract(1, "week").startOf("week");
      _toDate = today.clone().subtract(1, "week").endOf("week");
    } else if (dateType === CalenderFilterEnum.This_Month) {
      _fromDate = today.clone().startOf("month");
      _toDate = today.clone().endOf("month");
    } else if (dateType === CalenderFilterEnum.Last_Month) {
      _fromDate = today.clone().subtract(1, "month").startOf("month");
      _toDate = today.clone().subtract(1, "month").endOf("month");
    } else if (dateType === CalenderFilterEnum.This_Quarter) {
      _fromDate = today.clone().startOf("quarter");
      _toDate = today.clone().endOf("quarter");
    } else if (dateType === CalenderFilterEnum.Last_Quarter) {
      _fromDate = today.clone().subtract(1, "quarter").startOf("quarter");
      _toDate = today.clone().subtract(1, "quarter").endOf("quarter");
    } else if (dateType === CalenderFilterEnum.This_6_Months) {
      _fromDate = today.clone().subtract(6, "months").startOf("month");
      _toDate = today.clone().endOf("month");
    } else if (dateType === CalenderFilterEnum.Last_6_Months) {
      _fromDate = today.clone().subtract(12, "months").startOf("month");
      _toDate = today.clone().subtract(6, "months").endOf("month");
    } else if (dateType === CalenderFilterEnum.This_Year) {
      _fromDate = today.clone().startOf("year");
      _toDate = today.clone().endOf("year");
    } else if (dateType === CalenderFilterEnum.Last_Year) {
      _fromDate = today.clone().subtract(1, "year").startOf("year");
      _toDate = today.clone().subtract(1, "year").endOf("year");
    }
    return { fromDate: _fromDate, toDate: _toDate };
  };

  // Set your static currency symbols here
  const staticCurrencySymbols = {
    currency1: "$", // Example: US Dollar
    currency2: "€", // Example: Euro
    currency3: "₹", // Example: Rupee
  };

  const hasActionAccess = (moduleId, mActionId) => {
    let userAccess = localStorage.getItem("userAccess");
    userAccess = JSON.parse(userAccess);
    if (userAccess == undefined || userAccess.length === 0) {
      return;
    }
    const trueCount = userAccess.reduce((count, item) => {
      return count + (item.setDefaultAction === true ? 1 : 0);
    }, 0);

    // Set the access count based on the total count of true values
    let newAccessCount = trueCount > 0 ? trueCount : 0;

    SetAccessCount(newAccessCount);

    // Store accessCount in local storage
    localStorage.setItem("accessCount", newAccessCount);

    const action = userAccess?.find(
      (act) => act.mActionId === mActionId && act.moduleID === moduleId
    );

    if (action && action.setDefaultAction === true) {
      return true;
    }
    return false;
  };

  // Set Access with key mActionIds
  const SetAccessData = () => {
    // setLoader(true)
    setUserAccessData({
      ...userAccessData,
      Dashboard_CanView: true,

      //ADMIN :
      // Prospect:
      Admin_Prospect_CanAdd: hasActionAccess(1, 1),
      Admin_Prospect_CanEdit: hasActionAccess(1, 2),
      Admin_Prospect_CanDelete: hasActionAccess(1, 3),
      Admin_Prospect_CanView: hasActionAccess(1, 4),

      //Proposal :
      Admin_Proposal_CanAdd: hasActionAccess(2, 5),
      Admin_Proposal_CanEdit: hasActionAccess(2, 6),
      Admin_Proposal_CanDelete: hasActionAccess(2, 7),
      Admin_Proposal_CanView: hasActionAccess(2, 8),

      //Engagement Latter :
      Admin_Engagement_Latter_CanAdd: hasActionAccess(3, 9),
      Admin_Engagement_Latter_CanEdit: hasActionAccess(3, 10),
      Admin_Engagement_Latter_CanDelete: hasActionAccess(3, 11),
      Admin_Engagement_Latter_CanView: hasActionAccess(3, 12),

      //Config Admin:
      Admin_Config_CanView: hasActionAccess(7, 28),

      //Config Admin Service Cat:
      Admin_Config_ServiceCat_CanAdd: hasActionAccess(19, 73),
      Admin_Config_ServiceCat_CanEdit: hasActionAccess(19, 74),
      Admin_Config_ServiceCat_CanDelete: hasActionAccess(19, 75),
      Admin_Config_ServiceCat_CanView: hasActionAccess(19, 76),

      //Config Admin Service:
      Admin_Config_Service_CanAdd: hasActionAccess(19, 73),
      Admin_Config_Service_CanEdit: hasActionAccess(19, 74),
      Admin_Config_Service_CanDelete: hasActionAccess(19, 75),
      Admin_Config_Service_CanView: hasActionAccess(19, 76),

      //Config Admin Package:
      Admin_Config_ServicePackage_CanAdd: hasActionAccess(19, 73),
      Admin_Config_ServicePackage_CanEdit: hasActionAccess(19, 74),
      Admin_Config_ServicePackage_CanDelete: hasActionAccess(19, 75),
      Admin_Config_ServicePackage_CanView: hasActionAccess(19, 76),

      //Config Admin Global constant:
      Admin_Config_Global_Constant_CanAdd: hasActionAccess(20, 77),
      Admin_Config_Global_Constant_CanEdit: hasActionAccess(20, 78),
      Admin_Config_Global_Constant_CanDelete: hasActionAccess(20, 79),
      Admin_Config_Global_Constant_CanView: hasActionAccess(20, 80),

      //Config Admin GlobalDriver:
      Admin_Config_Global_Driver_CanAdd: hasActionAccess(20, 77),
      Admin_Config_Global_Driver_CanEdit: hasActionAccess(20, 78),
      Admin_Config_Global_Driver_CanDelete: hasActionAccess(20, 79),
      Admin_Config_Global_Driver_CanView: hasActionAccess(20, 80),

      //Config Admin EL/PL Template:
      Admin_Config_Template_CanAdd: hasActionAccess(21, 81),
      Admin_Config_Template_CanEdit: hasActionAccess(21, 82),
      Admin_Config_Template_CanDelete: hasActionAccess(21, 83),
      Admin_Config_Template_CanView: hasActionAccess(21, 84),

      //Config Admin Term and Condition:
      Admin_Config_TnC_CanAdd: hasActionAccess(21, 81),
      Admin_Config_TnC_CanEdit: hasActionAccess(21, 82),
      Admin_Config_TnC_CanDelete: hasActionAccess(21, 83),
      Admin_Config_TnC_CanView: hasActionAccess(21, 84),

      //Config Admin Email Template:
      Admin_Config_Email_Template_CanAdd: hasActionAccess(21, 81),
      Admin_Config_Email_Template_CanEdit: hasActionAccess(21, 82),
      Admin_Config_Email_Template_CanDelete: hasActionAccess(21, 83),
      Admin_Config_Email_Template_CanView: hasActionAccess(21, 84),

      //Setting Admin
      Admin_Setting_CanView: hasActionAccess(9, 36),

      //Config Admin Setting User:
      Admin_Setting_user_CanAdd: hasActionAccess(22, 85),
      Admin_Setting_user_CanEdit: hasActionAccess(22, 86),
      Admin_Setting_user_CanDelete: hasActionAccess(22, 87),
      Admin_Setting_user_CanView: hasActionAccess(22, 88),

      //Config Admin Setting User:
      Admin_Setting_AccessKeyCanAdd: hasActionAccess(23, 89),
      Admin_Setting_AccessKeyCanEdit: hasActionAccess(23, 90),
      Admin_Setting_AccessKeyCanDelete: hasActionAccess(23, 91),
      Admin_Setting_AccessKeyCanView: hasActionAccess(23, 92),
      //Config Admin Setting User:

      Admin_Setting_Practice_Config_CanAdd: hasActionAccess(24, 93),
      Admin_Setting_Practice_Config_CanEdit: hasActionAccess(24, 94),
      Admin_Setting_Practice_Config_CanDelete: hasActionAccess(24, 95),
      Admin_Setting_Practice_Config_CanView: hasActionAccess(24, 96),

      //Config Admin Setting User:
      Admin_Activity_Log_CanAdd: hasActionAccess(25, 97),
      Admin_Activity_Log_CanEdit: hasActionAccess(25, 98),
      Admin_Activity_Log_CanDelete: hasActionAccess(25, 99),
      Admin_Activity_Log_CanView: hasActionAccess(25, 100),

      //Config Admin Setting User:
      Admin_Personalize_SettingCanEdit: hasActionAccess(26, 102),
      Admin_Personalize_SettingCanView: hasActionAccess(26, 104),

      //SUPER ADMIN
      //Organisation:
      Organisation_CanAdd: hasActionAccess(4, 13),
      Organisation_CanEdit: hasActionAccess(4, 14),
      Organisation_CanDelete: hasActionAccess(4, 15),
      Organisation_CanView: hasActionAccess(4, 16),

      //User:
      User_CanAdd: hasActionAccess(5, 17),
      User_CanEdit: hasActionAccess(5, 18),
      User_CanDelete: hasActionAccess(5, 19),
      User_CanView: hasActionAccess(5, 20),

      //Subscription:
      // Subscription_CanAdd: true,//hasActionAccess(6, 21),
      // Subscription_CanEdit: hasActionAccess(6, 22),
      // Subscription_CanDelete: hasActionAccess(6, 23),
      Subscription_CanView: hasActionAccess(6, 24),

      //Config SuperAdmin Subscription0-Package:
      SuperAdmin_Config_Subscription_Package_CanAdd: hasActionAccess(11, 41),
      SuperAdmin_Config_Subscription_Package_CanEdit: hasActionAccess(11, 42),
      SuperAdmin_Config_Subscription_Package_CanDelete: hasActionAccess(11, 43),
      SuperAdmin_Config_Subscription_Package_CanView: hasActionAccess(11, 44),

      //Config SuperAdmin Subscription-User:
      SuperAdmin_Config_Subscription_User_CanAdd: hasActionAccess(12, 45),
      SuperAdmin_Config_Subscription_User_CanEdit: hasActionAccess(12, 46),
      SuperAdmin_Config_Subscription_User_CanDelete: hasActionAccess(12, 47),
      SuperAdmin_Config_Subscription_User_CanView: hasActionAccess(12, 48),

      //Config SuperAdmin Subscription0-Invoices:
      SuperAdmin_Config_Subscription_Invoices_CanAdd: hasActionAccess(13, 49),
      SuperAdmin_Config_Subscription_Invoices_CanEdit: hasActionAccess(13, 50),
      SuperAdmin_Config_Subscription_Invoices_CanDelete: hasActionAccess(
        13,
        51
      ),
      SuperAdmin_Config_Subscription_Invoices_CanView: hasActionAccess(13, 52),

      //Super Admin Config
      SuperAdmin_Config_CanView: hasActionAccess(8, 32),

      //Config SuperAdmin Service Cat:
      SuperAdmin_Config_ServiceCat_CanAdd: hasActionAccess(14, 53),
      SuperAdmin_Config_ServiceCat_CanEdit: hasActionAccess(14, 54),
      SuperAdmin_Config_ServiceCat_CanDelete: hasActionAccess(14, 55),
      SuperAdmin_Config_ServiceCat_CanView: hasActionAccess(14, 56),

      //Config SuperAdmin Service:
      SuperAdmin_Config_Service_CanAdd: hasActionAccess(14, 53),
      SuperAdmin_Config_Service_CanEdit: hasActionAccess(14, 54),
      SuperAdmin_Config_Service_CanDelete: hasActionAccess(14, 55),
      SuperAdmin_Config_Service_CanView: hasActionAccess(14, 56),

      //Config SuperAdmin Package:
      SuperAdmin_Config_ServicePackage_CanAdd: hasActionAccess(14, 53),
      SuperAdmin_Config_ServicePackage_CanEdit: hasActionAccess(14, 54),
      SuperAdmin_Config_ServicePackage_CanDelete: hasActionAccess(14, 55),
      SuperAdmin_Config_ServicePackage_CanView: hasActionAccess(14, 56),

      //Config SuperAdmin Global constant:
      SuperAdmin_Config_Global_Constant_CanAdd: hasActionAccess(15, 57),
      SuperAdmin_Config_Global_Constant_CanEdit: hasActionAccess(15, 58),
      SuperAdmin_Config_Global_Constant_CanDelete: hasActionAccess(15, 59),
      SuperAdmin_Config_Global_Constant_CanView: hasActionAccess(15, 60),

      //Config SuperAdmin GlobalDriver:
      SuperAdmin_Config_Global_Driver_CanAdd: hasActionAccess(15, 57),
      SuperAdmin_Config_Global_Driver_CanEdit: hasActionAccess(15, 58),
      SuperAdmin_Config_Global_Driver_CanDelete: hasActionAccess(15, 59),
      SuperAdmin_Config_Global_Driver_CanView: hasActionAccess(15, 60),

      //Config SuperAdmin EL/PL Template:
      SuperAdmin_Config_Template_CanAdd: hasActionAccess(16, 61),
      SuperAdmin_Config_Template_CanEdit: hasActionAccess(16, 62),
      SuperAdmin_Config_Template_CanDelete: hasActionAccess(16, 63),
      SuperAdmin_Config_Template_CanView: hasActionAccess(16, 64),

      //Config SuperAdmin Term and Condition:
      SuperAdmin_Config_TnC_CanAdd: hasActionAccess(16, 61),
      SuperAdmin_Config_TnC_CanEdit: hasActionAccess(16, 62),
      SuperAdmin_Config_TnC_CanDelete: hasActionAccess(16, 63),
      SuperAdmin_Config_TnC_CanView: hasActionAccess(16, 64),

      //Config SuperAdmin Email Template:
      SuperAdmin_Config_Email_Template_CanAdd: hasActionAccess(16, 61),
      SuperAdmin_Config_Email_Template_CanEdit: hasActionAccess(16, 62),
      SuperAdmin_Config_Email_Template_CanDelete: hasActionAccess(16, 63),
      SuperAdmin_Config_Email_Template_CanView: hasActionAccess(16, 64),

      SuperAdmin_Setting_CanView: true, //hasActionAccess(10, 40),

      // SuperAdmin_Setting_User_Role_CanAdd: true,//hasActionAccess(17, 65),
      // SuperAdmin_Setting_User_Role_CanEdit: true,//hasActionAccess(17, 66),
      // SuperAdmin_Setting_User_Role_CanDelete: true,// hasActionAccess(17, 67),
      // SuperAdmin_Setting_User_Role_CanView: true,// hasActionAccess(17, 68),

      // SuperAdmin_Personalize_SettingCanAdd: hasActionAccess(27, 105),
      SuperAdmin_Personalize_SettingCanEdit: hasActionAccess(27, 106),
      // SuperAdmin_Personalize_SettingCanDelete: hasActionAccess(27, 107),
      SuperAdmin_Personalize_SettingCanView: hasActionAccess(27, 108),
      //Config SuperAdmin Email Template:
      SuperAdmin_Setting_Email_Template_CanAdd: hasActionAccess(18, 69),
      SuperAdmin_Setting_Email_Template_CanEdit: hasActionAccess(18, 70),
      SuperAdmin_Setting_Email_Template_CanDelete: hasActionAccess(18, 71),
      SuperAdmin_Setting_Email_Template_CanView: hasActionAccess(18, 72),

      SuperAdmin_Setting_User_Role_CanAdd: hasActionAccess(17, 65),
      SuperAdmin_Setting_User_Role_CanEdit: hasActionAccess(17, 66),
      SuperAdmin_Setting_User_Role_CanDelete: hasActionAccess(17, 67),
      SuperAdmin_Setting_User_Role_CanView: true, // hasActionAccess(17, 68),
    });
  };

  const GetUserPersonalizeSettingData = async (id) => { };

  // Used in Html Editor , validation
  const HtmlToPlainText = (newText, ModuleName) => {
    const cleanText = newText
      .replace(/<p>[\s\n\r]*<br\s*\/?>[\s\n\r]*<\/p>/gi, "")
      .replace(/ style="[^"]*"/g, "")
      .replace(/<(?!img)[^>]*>/g, "") // Remove HTML tags except <img>
      .replace(/&nbsp;/g, " ") // Replace &nbsp; with space
      .replace(/\s+/g, " ") // Replace multiple whitespaces with a single space
      // Remove empty paragraphs with just <br> tags
      .replace(/<br\s*\/?>/gi, "") // Remove <br> tags
      .trim(); // Trim leading and trailing spaces

    return cleanText;
  };
  const RecallGetUserPersonalizeSettingData = (id) => {
    if (getUserPersonalizeSettingApiCallCount < maxCountToRecallApi) {
      getUserPersonalizeSettingApiCallCount += 1;
      setTimeout(function () {
        GetUserPersonalizeSettingData(id);
      }, 1000);
    } else {
      // Stop loader after maximum API call attempts
      // setLoader(false);
    }
    // setLoader(false);
  };
  const handleInputChange = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;

    if (
      inputValue.length === 0 ||
      (inputValue.length === 1 && inputValue[0] === " ")
    ) {
      setUpdatedProspectName("");
    } else {
      const formattedValue =
        inputValue.trim().charAt(0).toUpperCase() + inputValue.slice(1);
      setUpdatedProspectName(formattedValue);
    }
  };

  const handleNameChange = () => {
    if (updatedProspectName.trim() !== "") {
      setProspectName(updatedProspectName);
    }
    setUpdatedProspectName("");
  };

  const handleInputChangeProposal = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;

    if (
      inputValue.length === 0 ||
      (inputValue.length === 1 && inputValue[0] === " ")
    ) {
      setUpdatedProposalName("");
    } else {
      const formattedValue =
        inputValue.trim().charAt(0).toUpperCase() + inputValue.slice(1);
      setUpdatedProposalName(formattedValue);
      setDefaultVariables(false);
    }
  };

  const handleNameChangeProposal = () => {
    if (updatedProposalName.trim() !== "") {
      setProposalName(updatedProposalName);
    }
    setUpdatedProposalName("");
  };

  const handleInputChangeEngagement = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;

    if (
      inputValue.length === 0 ||
      (inputValue.length === 1 && inputValue[0] === " ")
    ) {
      setUpdatedEngagementName("");
    } else {
      const formattedValue =
        inputValue.trim().charAt(0).toUpperCase() + inputValue.slice(1);

      // Update the state with the formatted value
      setUpdatedEngagementName(formattedValue);
      setDefaultVariables(false);
    }
  };

  const handleNameChangeEngagement = () => {
    if (updatedEngagementName.trim() !== "") {
      setEngagementName(updatedEngagementName);
    }
    setUpdatedEngagementName("");
  };

  const handleSetDefaultVariables = () => {
    setDefaultVariables(!DefaultVariables);

    if (!DefaultVariables) {
      // Set default values
      setUpdatedEngagementName("Engagement Letter");
      setUpdatedProposalName("Proposal");
      setUpdatedProspectName("Prospect");
    } else {
      // Set values from settings
      setUpdatedEngagementName("");
      setUpdatedProposalName("");
      setUpdatedProspectName("");
    }
  };
  const setInitializeValidationError = () => {
    setRequireErrorMessage("");
  };

  const getCrudButtonTextName = (actionName, moduleName) => {
    let crudButtonTextName = "";
    if (actionName === "Cancel") {
      crudButtonTextName = actionName;
    } else {
      crudButtonTextName = actionName + " " + moduleName;
    }
    return crudButtonTextName;
  };

  const getCrudPopUpTitleName = (actionName, moduleName) => {
    let crudPopUpTitleName = actionName + " " + moduleName;
    return crudPopUpTitleName;
  };

  const getCrudButtonToolTipName = (actionName, moduleName) => {
    let crudButtonToolTipName = "";
    if (moduleName === undefined) {
      crudButtonToolTipName = actionName;
    } else {
      crudButtonToolTipName = actionName + " " + moduleName;
    }
    return crudButtonToolTipName;
  };

  const getPlaceholderTextName = (actionName, moduleName) => {
    let placeholderText = actionName + " " + moduleName;
    return placeholderText;
  };

  const isValidEmail = (email) => {
    const emailRegex =
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9-]+(?:\.[A-Za-z0-9-]+)*(?!\.{2})\.[A-Za-z]{2,}$/;

    return emailRegex.test(email);
  };
  function formatValue(value, id) {
    // Ensure value is not null
    value = value == null ? 0 : value;

    // Split the value into integer and decimal parts
    let valueArray = value.toString().split(".");
    let valueLength = "";

    // Ensure valueArray[1] is defined and is a string
    if (
      valueArray &&
      valueArray.length > 1 &&
      typeof valueArray[1] === "string"
    ) {
      valueLength = valueArray[1];
    }

    let valueWithExactTwoPrecision = value.toString();

    // Check if valueLength is defined and has a length of at least 2
    if (valueLength && valueLength.length > 2) {
      valueWithExactTwoPrecision = (Math.floor(value * 100) / 100).toFixed(2);
    }

    // Determine the currency symbol based on the id
    let currencySymbol = "";
    switch (1) {
      case 1:
        currencySymbol = "£"; // Pound
        break;
      case 2:
        currencySymbol = "$"; // Dollar
        break;
      case 3:
        currencySymbol = "€"; // Euro
        break;
      // Add more cases for different currency symbols as needed
      default:
        currencySymbol = ""; // Default: No currency symbol
    }

    // Format the value with comma separators
    const formattedValue = `${currencySymbol}${Number(valueWithExactTwoPrecision)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;


    return `${formattedValue}`;
  }


  function formatValueWithoutCurrencySymbol(value, id) {
    // Ensure value is not null
    value = value == null ? 0 : value;

    // Split the value into integer and decimal parts
    let valueArray = value.toString().split(".");
    let valueLength = "";

    // Ensure valueArray[1] is defined and is a string
    if (
      valueArray &&
      valueArray.length > 1 &&
      typeof valueArray[1] === "string"
    ) {
      valueLength = valueArray[1];
    }

    let valueWithExactTwoPrecision = value.toString();

    // Check if valueLength is defined and has a length of at least 2
    if (valueLength && valueLength.length > 2) {
      valueWithExactTwoPrecision = (Math.floor(value * 100) / 100).toFixed(2);
    }


    // Format the value with comma separators
    const formattedValue = `${Number(valueWithExactTwoPrecision)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;


    return `${formattedValue}`;
  }
  // function formatValue(value, value2) {

  //   value = value == null ? 0 : value;
  //   let valueArray = value?.toString().split(".");
  //   let valueLength = "";
  //   // Ensure valueArray[1] is defined and is a string
  //   if (
  //     valueArray &&
  //     valueArray.length > 1 &&
  //     typeof valueArray[1] === "string"
  //   ) {
  //     valueLength = valueArray[1];
  //   }
  //   let valueWithExactTwoPrecision = value.toString();
  //   // Check if valueLength is defined and has a length of at least 2
  //   if (valueLength && valueLength.length > 2) {
  //     valueWithExactTwoPrecision = (Math.floor(value * 100) / 100).toFixed(2);
  //   }
  //   return `${Number(valueWithExactTwoPrecision)
  //     .toFixed(2)
  //     .toString()
  //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  // }

  const GetTwoDecimalValueWithoutRoundOff = (value) => {
    value = value == null ? 0 : value;
    let valueArray = value.toString().split(".");
    let valueLength = "";

    // Ensure valueArray[1] is defined and is a string
    if (
      valueArray &&
      valueArray.length > 1 &&
      typeof valueArray[1] === "string"
    ) {
      valueLength = valueArray[1];
    }

    let valueWithExactTwoPrecision = value.toString();

    // Check if valueLength is defined and has a length of at least 2
    if (valueLength && valueLength.length > 2) {
      valueWithExactTwoPrecision = Number(Math.floor(value * 100) / 100).toFixed(2);
    }
    return Number(valueWithExactTwoPrecision)
  }
  function hasHyphenAfterNumber(text) {
    const pattern = /(\d)-/g;
    let sanitizedText = text.replace(pattern, '$1'); // Remove hyphen if it follows a digit
    sanitizedText = sanitizedText.replace(/-/g, (match, index) => (index === 0 ? match : "")); // Keep hyphen only at the start
    return sanitizedText;
  }
  const getValidationMessage = (
    requireMessage,
    maxDiscountForQC,
    defaultDiscount
  ) => {
    if (!requireMessage) return null;

    const parsedDefaultDiscount = Number(defaultDiscount);

    if (isNaN(parsedDefaultDiscount)) {
      return <span className="validation">Invalid discount</span>;
    }

    const minDiscount = -999.0;
    const maxDiscount = maxDiscountForQC || 100;

    if (
      defaultDiscount !== "" &&
      defaultDiscount !== null &&
      defaultDiscount !== undefined &&
      (parsedDefaultDiscount < minDiscount || parsedDefaultDiscount > maxDiscount)
    ) {
      return (
        <>
          <span className="validation">
            The discount should be between {minDiscount}% and {maxDiscount}%.
          </span>
          <button
            style={{
              fontSize: "12px",
              border: "none",
              background: "transparent",
              color: "#626ED4",
            }}
            className="float-sm-end"
            data-bs-toggle="modal"
            data-bs-target="#pricingModel"
          >
            + Pricing Setting
          </button>
        </>
      );
    }

    return null;
  };




  /* -------------- Set All Function Used Globally Throughout The Project ------------ */
  return (
    <AuthContextProvider.Provider
      value={{
        topbar,
        loader,
        listCount,
        setTopbar,
        isPopupOpen,
        prospectName,
        setIsPopupOpen,
        proposalName,
        EngagementName,
        setLoader,
        loginLoader,
        setLoginLoader,
        isMobile,
        totalPage,
        formatValue,
        formatValueWithoutCurrencySymbol,
        isValidEmail,
        setListCount,
        accessCount,
        GetCustomDate,
        orgLoaderList,
        userAccessData,
        SetAccessCount,
        desktopRecords,
        isMobileRecords,
        setProposalName,
        hasActionAccess,
        handleNameChange,
        DefaultVariables,
        HtmlToPlainText,
        setProspectName,
        setOrgLoaderList,
        handleInputChange,
        setEngagementName,
        setDefaultProposal,
        setDefaultProspect,
        activeOrganization,
        maxCountToRecallApi,
        updatedProspectName,
        updatedProposalName,
        RequireErrorMessage,
        setDefaultVariables,
        problematicInputRef,
        staticCurrencySymbols,
        setProfessionTypeList,
        setActiveOrganization,
        getCrudButtonTextName,
        getCrudPopUpTitleName,
        updatedEngagementName,
        setUpdatedProposalName,
        setUpdatedProspectName,
        getPlaceholderTextName,
        professionTypeListData,
        setRequireErrorMessage,
        scrollUpDownByElementID,
        DashboardCountListLoader,
        handleNameChangeProposal,
        getCrudButtonToolTipName,
        handleInputChangeProposal,
        handleSetDefaultVariables,
        scrollUptoCurrentPosition,
        DashboardActivityLogLoader,
        setDefaultEngagementLatter,
        handleNameChangeEngagement,
        handleInputChangeEngagement,
        setDashboardCountListLoader,
        setInitializeValidationError,
        setDashboardActivityLogLoader,
        activeOrganizationSubscriptionPlan,
        setActiveOrganizationSubscriptionPlan,
        isAddUpdatePurchaseDone,
        setIsAddUpdatePurchaseDone,
        handleErrorMessage,
        GetTwoDecimalValueWithoutRoundOff,
        hasHyphenAfterNumber,
        getValidationMessage,
        logoutTimeUpModal,
        setLogoutTimeUpModal,
        handleReloadClick,
        updateImageUrlsInHtml,
        updateTemplateList
      }}
    >
      {children}
    </AuthContextProvider.Provider>
  );
};

export default AuthContext;
