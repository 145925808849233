import React, { useContext, useEffect, useState } from "react";
import Select from "react-select"; // Assuming you have react-select installed
import { AuthContextProvider } from "../../../AuthContext/AuthContext";
import BackButtonSvg from "../../../components/BackButtonSvg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Reminderjson from "../Reminderjson";
import { GetEmailTemplateListLookupList } from "../../../redux/Services/Setting/EmailTemplateLookuplistLoginToOutbook";
import { useDispatch, useSelector } from "react-redux";
import SuccessModal from "../../../components/SuccessModal";

import { GetReminderModel } from "../../../redux/Services/Config/ReminderCrudApi";

import {
  UpdatePaidAccount,
  UpdateUnpaidAccount,
} from "../../../redux/Services/Setting/LoginToOutbooksReminder";

function UnpaidUpdateAccountDeletion() {
  const navigate = useNavigate();
  const { id } = useParams();
  const moduleName = "Reminder";

  const handleCancelButton = () => {
    setTopbar("block");
    navigate("/paid-unpaid-list");
  };

  const {
    setTopbar,
    setLoader,
    getCrudButtonTextName,
    getCrudPopUpTitleName,
    scrollUpDownByElementID,
    HtmlToPlainText,
    proposalName,
    EngagementName,
    hasActionAccess,
  } = useContext(AuthContextProvider);

  const common = useSelector((state) => state.Storage); // Getting Logged Users Details From Persist Storage of redux hooks
  let TemplateTypeCatID;
  let TemplateTypeID;

  const location = useLocation();

  const [sequence, setSequence] = useState();
  const [days, setDays] = useState();
  const [emailTemplateTypeLookupList, setemailTemplateTypeLookupList] =
    useState([]);
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [templateTypeID, setTemplateTypeID] = useState(null);
  const [modelAction, setModelAction] = useState("");
  const [dismissModal, setDismissModal] = useState(null);

  const [reminderName, setReminderName] = useState("");
  const [period, setPeriod] = useState(1);
  const [ErrorMessage, setErrorMessage] = useState({});
  const [requireErrorMessage, setRequireErrorMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const [modelRequestData, setModelRequestData] = useState({
    reminderName: null,
    reminderKeyID: id,
    organisationKeyID: null,
    userKeyID: null,
    templateID: null,
    emailAddressID: null,
    days: null, // Convert days to integer if not empty
    sequenceID: null,
    triggerPointID: null,
    isRepeat: null,
    reminderFrequencyID: null,
    documentStatusIDs: null,
    listType: null,

    period: null,
  });
  const [reminderobj, setReminderobj] = useState({
    reminderName: null,
    reminderKeyID: id,
    organisationKeyID: null,
    userKeyID: null,
    templateID: null,
    emailAddressID: null,
    days: null, // Convert days to integer if not empty
    sequenceID: null,
    triggerPointID: null,
    isRepeat: null,
    reminderFrequencyID: null,
    documentStatusIDs: null,
  });

  const [emailAddressID, setEmailAddressID] = useState(null);
  const [triggerPointID, setTriggerPointID] = useState(null);
  const [isRepeat, setIsRepeat] = useState(null);
  const [reminderFrequencyID, setReminderFrequencyID] = useState(null);
  const [documentStatusIDs, setDocumentStatusIDs] = useState(null);

  useEffect(() => {
    setTopbar("none");
  }, []);

  useEffect(() => {
    if (location?.state) {
      setTemplateTypeID(location.state.templateTypeID);
      GetEmailTemplateTypeData(location.state.templateTypeID);
      setModelAction(location.state?.Action || "Unpaid"); // If Action is undefined or null, set to "Unpaid"
      GetReminderModelData(location.state.reminderKeyID);
    }

    setTopbar("none");
  }, [location.state, id]);

  const titleVariable = location.state?.listType;

  const GetEmailTemplateTypeData = async (TemplateTypeID) => {
    try {
      const data = await GetEmailTemplateListLookupList(
        common.organisationKeyID,
        common.userKeyID,
        (TemplateTypeCatID = 6),
        TemplateTypeID === undefined ? templateTypeID : TemplateTypeID
      );
      let emailTemplateTypeData = data?.data?.responseData.data;
      emailTemplateTypeData = emailTemplateTypeData.map((TempType) => ({
        value: TempType.templateID,
        label: TempType.templateName,
      }));
      setemailTemplateTypeLookupList(emailTemplateTypeData);
    } catch (error) {
      console.log(error);
    }
  };

  const GetReminderModelData = async (id) => {
    if (!id) return;
    try {
      setLoader(true);
      const data = await GetReminderModel(id);
      if (data?.data?.statusCode === 200) {
        if (data?.data?.responseData?.data) {
          const ModelData = data?.data?.responseData?.data;
          setReminderobj({
            ...reminderobj,
            reminderKeyID: ModelData.reminderKeyID,
            reminderName: ModelData.reminderName,
            templateID: ModelData.templateID,
            emailAddressID: ModelData.emailAddressID,
            days: ModelData.days,
            sequenceID: ModelData.sequenceID,
            triggerPointID: ModelData.triggerPointID,
            isRepeat: ModelData.isRepeat,
            reminderFrequencyID: ModelData.reminderFrequencyID,
            documentStatusIDs: ModelData.documentStatusIDs,
          });
          setReminderName(ModelData.reminderName);
          setDays(ModelData.days);
          setEmailTemplate(ModelData.templateID);
          setSequence(
            Reminderjson.periods.find(
              (period) => period.id === ModelData.sequenceID
            )
          );
          setTriggerPointID(ModelData.triggerPointID);
          setIsRepeat(ModelData.isRepeat);
          setReminderFrequencyID(ModelData.reminderFrequencyID);
          setDocumentStatusIDs(ModelData.documentStatusIDs);
        }
      } else {
        setErrorMessage(data?.data?.errorMessage);
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const templateTypeFilter = emailTemplateTypeLookupList?.filter(
    (template) => template.value == emailTemplate
  );
  const Sequence = Reminderjson.periods?.filter(
    (sequence) => sequence.id == period
  );

  const handleSubmit = async () => {
    const daysInt = parseInt(days);

    if (Object.keys(errors).length === 0) {
      const formData = {
        reminderKeyID: location.state?.reminderKeyID,
        organisationKeyID: common.organisationKeyID,
        userKeyID: common.userKeyID,
        reminderName: reminderName,
        templateID: emailTemplate,
        emailAddressID: emailAddressID || null,
        days: days === "" ? null : parseInt(days),
        sequenceID: sequence ? sequence.id : null,
        triggerPointID: triggerPointID || null,
        isRepeat: isRepeat || null,
        reminderFrequencyID: reminderFrequencyID || null,
        documentStatusIDs: documentStatusIDs || null,
      };

      try {
        let response;
        if (modelAction === "Paid") {
          response = await UpdatePaidAccount(
            "/AddUpdateLoginToOutbooksReminderPaidUser",
            formData
          );
        } else {
          response = await UpdateUnpaidAccount(
            "/AddUpdateLoginToOutbooksReminderUnpaidUser",
            formData
          );
        }

        if (response?.data?.statusCode === 200) {
          setOpenSuccessModal(true);
        } else {
          console.error(response?.response?.data?.errorMessage);
        }
      } catch (error) {
        console.error("API call error:", error);
      }
    }
  };

  const handleClose = () => {
    setOpenSuccessModal(false);
    navigate("/paid-unpaid-list");
  };

  return (
    <>
      <div
        className="create-practice-height scrollbar mt-5 form-row"
        style={{ maxHeight: "600px" }}
      >
        <div className="tab-content mt-2">
        <h3 class="modal-title">
              <BackButtonSvg onClick={handleCancelButton} />
              {titleVariable === "Unpaid"
                ? getCrudPopUpTitleName("Unpaid", moduleName)
                : getCrudPopUpTitleName("Paid", moduleName)}
            </h3>
            <div class="separator mb-3"></div>
          <div className="row">
            
            <div className="col-lg-6 mt-2">
              <div className="mb-3">
                <label className="form-label">
                  Reminder Name <span className="text-danger">*</span>
                </label>
                <div className="input-group input-height">
                  <input
                    readOnly
                    type="text"
                    className="input-text"
                    placeholder="Enter Reminder Name"
                    value={reminderName}
                    onChange={(e) => setReminderName(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 mt-2">
              <div className="mb-3">
                <label className="form-label">
                  Email Templates <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <Select
                    className="user-role-select"
                    placeholder="Select..."
                    options={emailTemplateTypeLookupList}
                    value={templateTypeFilter}
                    onChange={(e) => setEmailTemplate(e.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">
                  Days <span className="text-danger">*</span>
                </label>
                <div className="input-group input-height">
                  <input
                    type="text"
                    className="input-text"
                    placeholder="Enter Days"
                    value={days}
                    onChange={(e) => setDays(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6" id="NOB_Div">
              <div className="mb-3">
                <label className="form-label">
                  Sequence <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <Select
                    className="user-role-select"
                    placeholder="Select..."
                    options={Reminderjson.periods}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={Sequence}
                    onChange={(value) => setPeriod(value.id)}
                  />
                </div>
              </div>
            </div>

            <div className="separator"></div>
            <div className="row fieldset modal-footer">
              <div className="col-lg-12 hstack gap-2 justify-content-end text-right mt-3">
                <button
                  className="btn btn-md btn-light"
                  onClick={handleCancelButton}
                >
                  <span>Cancel</span>
                </button>
                <button
                  className="btn btn-md btn-success create-item-btn"
                  onClick={handleSubmit}
                >
                  <span>
                    {titleVariable === "Unpaid"
                      ? "Update Unpaid Account"
                      : "Update Paid Account"}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        handleClose={handleClose}
        setDismissModal={setDismissModal}
        setOpenSuccessModal={setOpenSuccessModal}
        openSuccessModal={openSuccessModal}
        modelAction={modelAction}
        message={`${moduleName}  ${reminderName}`}
      />
    </>
  );
}

export default UnpaidUpdateAccountDeletion;
